<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <!-- <a-col :sm='24' :md='12' :xl='6' :style="{ marginBottom: '24px' }">
        <chart-card :loading='loading' title='已通过' :total='dashboard.pass'>
          <a-tooltip title='已通过' slot='action'>
            <a-icon type='info-circle-o' />
          </a-tooltip>
        </chart-card>
      </a-col>
      <a-col :sm='24' :md='12' :xl='6' :style="{ marginBottom: '24px' }">
        <chart-card :loading='loading' title='不通过' :total='dashboard.reject'>
          <a-tooltip title='不通过' slot='action'>
            <a-icon type='info-circle-o' />
          </a-tooltip>
        </chart-card>
      </a-col> -->
      <a-col :sm='24' :md='12' :xl='6' :style="{ marginBottom: '24px' }">
        <chart-card :loading='loading' title='待审核' :total='dashboard.audit'>
          <a-tooltip title='待审核' slot='action'>
            <a-icon type='info-circle-o' />
          </a-tooltip>
        </chart-card>
      </a-col>
    </a-row>
    <!-- <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 350px" placeholder="手机号/商户名/地区" />
      </a-form-model-item>
      <a-form-model-item prop='merchantAuditStatus'>
        <a-select v-model="form.merchantAuditStatus" placeholder="状态" style="width: 250px">
          <a-select-option :value="3">
            已通过
          </a-select-option>
          <a-select-option :value="2">
            不通过
          </a-select-option>
          <a-select-option :value="1">
            待审核
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model> -->
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 100}' :data='loadData'>
        <span slot='mobile' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              {{ text }}
            </a>
          </template>
        </span>
        <span slot='merchantAuditStatus' slot-scope='text'>
          <template>
            <a-badge dot v-if='text=="1"' :status='"processing"' :text='"审核中"'></a-badge>
            <a-badge dot v-else-if='text=="2"' :status='"error"' :text='"不通过"'></a-badge>
            <a-badge dot v-else-if='text=="3"' :status='"success"' :text='"通过"'></a-badge>
            <a-badge dot v-else :status='"default"' :text='"其他"'></a-badge>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryApplyList, getApplyDashboard, deleteMerchantAudit } from '@/api/member'

const columns = [
  {
    width: 150,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 150,
    title: '商户名称',
    dataIndex: 'merchantName',
  },
  {
    width: 150,
    title: '负责人',
    dataIndex: 'masterName',
  },
  {
    width: 150,
    title: '地区',
    dataIndex: 'location',
  },
  {
    width: 200,
    title: '申请时间',
    dataIndex: 'merchantApplyTime',
  },
  {
    width: 150,
    title: '状态',
    dataIndex: 'merchantAuditStatus',
    scopedSlots: { customRender: 'merchantAuditStatus' }
  },
  {
    width: 100,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'MemberApplyList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      form: {
        keyword: ""
      },
      //统计数据
      loading: true,
      dashboard: {},
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryApplyList(Object.assign(parameter, this.form))
          .then(datum => {
            return datum
          })
      }
    }
  },
  created() {
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      getApplyDashboard().then(result => {
        this.dashboard = result;
        this.loading = false;
      })
    },
    handleDelete(record) {
      deleteMerchantAudit({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleEdit(record) {
      this.$router.push({ path: '/member/apply/edit', query: { id: record.id } })
    }
  }
}
</script>
